import { Button, Box, Container, Stack, Typography, Divider, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSetPassword, useSetUserPassword } from 'api/auth';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { TextField } from 'component/base/TextField';
import { useTranslation, Trans } from 'react-i18next';
import { useQueryParams } from 'component/hooks/queryParams';
import { useSnackbar } from 'component/hooks/useSnackbar';
import cookie from 'js-cookie';
import axios from 'axios';
import { linkHelper } from 'linkHelper';
import {
  PasswordStrength,
  digitsRegex,
  lowercaseRegex,
  uppercaseRegex,
  specialCharRegex,
} from '../Profile/ChangePassword';
import { setAuthorizationHeaders } from 'api/axiosService';

const PREFIX = 'Login';

const classes = {
  container: `${PREFIX}Container`,
};

const StyledContainer = styled(Container)({
  [`&.${classes.container}`]: {
    display: 'flex',
    minHeight: '100vh',
    paddingBottom: '1.50rem',
    paddingTop: '1.50rem',
    '& > *': {
      marginBottom: 'auto',
      marginTop: 'auto',
      width: '100%',
    },
  },
});

interface FormValue {
  email: string;
  password: string;
  token: string;
}

export default function SetPassword({ isWpSiteControl }: { readonly isWpSiteControl: boolean }) {
  const { enqueueSnackbar } = useSnackbar();
  const params = useQueryParams();
  const token = params.get('token') ?? '';
  const email = params.get('email') ?? '';
  const userId = params.get('user_id') ?? '';

  const methods = useForm<FormValue>({
    defaultValues: {
      password: '',
      token,
      email,
    },
  });
  const { handleSubmit } = methods;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const setPassword = useSetPassword(userId);
  const setUserPassword = useSetUserPassword(userId);

  const isValidPassword = (password: string) => {
    if (
      !digitsRegex.test(password) ||
      !lowercaseRegex.test(password) ||
      !uppercaseRegex.test(password) ||
      !specialCharRegex.test(password) ||
      password.length < 8
    ) {
      return t('password_does_not_meet_criteria');
    }
  };

  const onSubmit = async (data: FormValue) => {
    if (isWpSiteControl) {
      await setPassword.mutateAsync({
        password: data.password,
        token: data.token,
      });
      axios.defaults.headers.common.Authorization = false;
      cookie.remove('user_session');
      navigate(`/login?email=${encodeURIComponent(data.email)}`);
    } else {
      await setUserPassword.mutateAsync({
        password: data.password,
        token: data.token,
      });
      setAuthorizationHeaders(token);
      cookie.set('user_session', token, {
        expires: 3560,
        sameSite: 'strict',
      });
      navigate(linkHelper.dashboard.getLink());
    }
  };

  const renderHeading = () => {
    if (isWpSiteControl) {
      return (
        <Box alignItems="center">
          <Typography textAlign="center" variant="h2">
            {t('login.title')}
          </Typography>
          <Typography textAlign="center" variant="body2" color="grayText">
            {t('login.description')}
          </Typography>
        </Box>
      );
    }

    return (
      <Box alignItems="center">
        <Typography textAlign="center" variant="h2">
          Rocket.net
        </Typography>
        <Typography textAlign="center" variant="body2" color="grayText">
          {t('set_password.sub_heading')}
        </Typography>
      </Box>
    );
  };

  const password = methods.watch('password');

  return (
    <StyledContainer maxWidth="xs" className={classes.container}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack alignContent="center" alignItems="center" spacing={3.5}>
            {renderHeading()}
            <Divider sx={{ width: '100%' }} />
            <Typography variant="body2">
              <Trans i18nKey="set_password.instructions" />
            </Typography>
            <Stack spacing={3} sx={{ width: '85%' }}>
              <TextField
                name="email"
                disabled
                fullWidth
                label={t('email')}
                type="email"
                rules={{
                  required: true,
                }}
                sx={{ backgroundColor: 'white' }}
              />
              <PasswordStrength password={password} />
              <TextField
                name="password"
                disabled={setPassword.isPending}
                fullWidth
                label={t('password')}
                type="password"
                rules={{
                  required: true,
                  validate: password => {
                    return isValidPassword(password);
                  },
                }}
                sx={{ backgroundColor: 'white' }}
              />
            </Stack>
            <Button
              color="primary"
              variant="contained"
              disabled={setPassword.isPending}
              type="submit"
              sx={{ paddingLeft: '40px', paddingRight: '40px' }}
            >
              {t('set_password.button')}
            </Button>
          </Stack>
        </form>
      </FormProvider>
    </StyledContainer>
  );
}
